import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link, ResponsiveImage } from "@cdv/jazz-web/components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface Props extends React.ComponentPropsWithRef<any> {
  size: number;
}

export default function NewsList(props: Props): JSX.Element {
  const { size } = props;
  const [data, setData] = useState<object[]>();
  useEffect(() => {
    let request = new XMLHttpRequest();

    request.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        setData(JSON.parse(request.response)["data"]);
      }
    };
    request.open(
      "GET",
      "https://metodicke-centrum-admin.cdvinfo.cz/secure/api/news/list/" + size,
      true
    );
    request.send();
  }, [size]);
  const Blog = {
    borderRadius: "12px",
    div: {},
    ".link": {
      marginTop: "20px",
    },
    ".link a": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: "#0053a1",
      fontWeight: "600",
      borderBottom: "1px solid transparent",
      transitionDuration: "0.3s",
      width: "fit-content",
    },
    ".link a:hover": {
      borderBottom: "1px solid #0053a1",
      transitionDuration: "0.3s",
    },
  };
  const sectionA = {
    a: {
      color: "#8A8B8D !important",
    },
    "a:hover": {
      borderBottom: "1px solid #8A8B8D",
    },
  };
  return (
    <>
      {data ? (
        <Box>
          <Grid container spacing={4}>
            {data.map((item, index) => (
              <Grid item md={4} key={index}>
                <Box sx={Blog}>
                  <ResponsiveImage height="200px">
                    <img src={"https://metodicke-centrum-admin.cdvinfo.cz/" + item["image"]}></img>
                  </ResponsiveImage>
                  <Box>
                    <Typography
                      sx={{
                        color: "black",
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      {item["title"]}
                    </Typography>
                    <Box className="link">
                      <Box sx={sectionA}>
                        <Link to={"/detail/" + item["id"]}>Číst více {">>"}</Link>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

function getData() {}
