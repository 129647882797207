import * as React from "react";
import Layout from "../components/Layout";
import { HtmlHead, ResponsiveImage, Link } from "@cdv/jazz-web/components";
import { Typography, Grid, Container, Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NewsList from "../components/newsList";

export const Head = () => (
  <HtmlHead title="Kontakt | Metodické centrum pro dopravní psychologii">
    <meta name="foo" content="bar" />
  </HtmlHead>
);

export default function Page() {
  const aCss = {
    paddingTop: "96px",
    a: {
      textDecoration: "none",
      color: "#e05206",
      borderBottom: "1px solid transparent",
      transitionDuration: "0.3s",
    },
    "a:hover": {
      borderBottom: "1px solid #e05206",
      transitionDuration: "0.3s",
    },
  };
  return (
    <Layout isIndex={false}>
      <Container sx={aCss}>
        <Typography variant="h1" sx={{ marginBottom: "60px", paddingTop: "60px", color: "black" }}>
          Novinky
        </Typography>
        <NewsList size={3}></NewsList>
      </Container>
    </Layout>
  );
}
